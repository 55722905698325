import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/fontawesome";

import "./index.css";

import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();

const clock = document.getElementById("clock");
const tick = document.getElementById("tick");
const money = document.getElementById("money");
const paint = document.getElementById("paint");
const clock_box = document.getElementById("clock-box");
const tick_box = document.getElementById("tick-box");
const money_box = document.getElementById("money-box");
const paint_box = document.getElementById("paint-box");
const down_arrow = document.getElementById("down-arrow");
const websites = document.getElementsByClassName("website");
const left_arrow = document.getElementById("left-arrow");
const right_arrow = document.getElementById("right-arrow");
const services_c = document.getElementsByClassName("services-c");
const services_u = document.getElementsByClassName("services-u");
const services_text = document.getElementsByClassName("services-text");
const packages_u = document.getElementsByClassName("packages-u");
const packages_c = document.getElementsByClassName("packages-c");

for (let x = 0; x < packages_u.length; x++) {
  if (packages_u[x] !== null) {
    packages_u[x].addEventListener("click", (e) => {
      if (packages_c[x].classList.contains("opacity-0")) {
        for (let y = 0; y < packages_u.length; y++) {
          if (x === y) {
            packages_u[y].classList.add("opacity-0");
            packages_c[y].classList.remove("opacity-0");
          } else if (packages_u[y].classList.contains("opacity-0")) {
            packages_c[y].classList.add("opacity-0");
            packages_u[y].classList.remove("opacity-0");
          }
        }
      }
    });
  }
}

for (let x = 0; x < services_c.length; x++) {
  if (services_c[x] !== null) {
    services_c[x].addEventListener("click", (e) => {
      for (let y = 0; y < services_c.length; y++) {
        if (x === y) {
          services_c[y].classList.add("hidden");
          services_c[y].classList.remove("flex");
          services_u[y].classList.remove("hidden");
          services_u[y].classList.add("flex");
          services_text[y].classList.add("hidden");
        }
      }
    });
  }
}

for (let x = 0; x < services_u.length; x++) {
  if (services_u[x] !== null) {
    services_u[x].addEventListener("click", (e) => {
      for (let y = 0; y < services_u.length; y++) {
        if (x === y) {
          services_u[y].classList.add("hidden");
          services_u[y].classList.remove("flex");
          services_c[y].classList.remove("hidden");
          services_c[y].classList.add("flex");
          services_text[y].classList.remove("hidden");
        } else if (services_c[y].classList.contains("flex")) {
          services_c[y].classList.add("hidden");
          services_c[y].classList.remove("flex");
          services_u[y].classList.remove("hidden");
          services_u[y].classList.add("flex");
          services_text[y].classList.add("hidden");
        }
      }
    });
  }
}

let website_shown = 0;

const about_buttons = [
  {
    name: clock,
    clicked: true,
    clicked_class: "clock-clicked",
    unclicked_class: "clock-unclicked",
    box: clock_box,
  },
  {
    name: tick,
    clicked: false,
    clicked_class: "tick-clicked",
    unclicked_class: "tick-unclicked",
    box: tick_box,
  },
  {
    name: money,
    clicked: false,
    clicked_class: "money-clicked",
    unclicked_class: "money-unclicked",
    box: money_box,
  },
  {
    name: paint,
    clicked: false,
    clicked_class: "paint-clicked",
    unclicked_class: "paint-unclicked",
    box: paint_box,
  },
];

const website_buttons = [
  {
    name: left_arrow,
    hidden: true,
    sibbling: "left-hidden",
  },
  {
    name: right_arrow,
    hidden: false,
    sibbling: "right-hidden",
  },
];

for (let x = 0; x < about_buttons.length; x++) {
  if (about_buttons[x].name !== null) {
    about_buttons[x].name.addEventListener("click", (e) => {
      if (about_buttons[x].clicked === false) {
        for (let y = 0; y < about_buttons.length; y++) {
          if (x !== y) {
            if (about_buttons[y].clicked === true) {
              about_buttons[y].name.classList.remove(
                about_buttons[y].clicked_class
              );
              about_buttons[y].name.classList.add(
                about_buttons[y].unclicked_class
              );
              about_buttons[y].box.classList.add("opacity-0");
              about_buttons[y].clicked = false;
            }
          }
          about_buttons[x].name.classList.add(about_buttons[x].clicked_class);
          about_buttons[x].name.classList.remove(
            about_buttons[x].unclicked_class
          );
          about_buttons[x].box.classList.remove("opacity-0");
          about_buttons[x].clicked = true;
        }
      }
    });
  }
}

for (let x = 0; x < website_buttons.length; x++) {
  if (website_buttons[x].name !== null) {
    website_buttons[x].name.addEventListener("click", (e) => {
      if (website_buttons[x].hidden === false) {
        find_website_shown();
        if (website_buttons[x].name === left_arrow) {
          if (website_shown != 0) {
            websites[website_shown].classList.add("opacity-0");
            websites[website_shown - 1].classList.remove("opacity-0");
            if (website_shown === 1) {
              website_buttons[0].hidden = true;
              document
                .getElementById(website_buttons[0].sibbling)
                .classList.remove("opacity-0");
              website_buttons[0].name.classList.add("opacity-0");
            }
            if (website_shown === websites.length - 1) {
              website_buttons[1].hidden = false;
              document
                .getElementById(website_buttons[1].sibbling)
                .classList.add("opacity-0");
              website_buttons[1].name.classList.remove("opacity-0");
            }
          }
        }
        if (website_buttons[x].name === right_arrow) {
          if (website_shown != websites.length - 1) {
            websites[website_shown].classList.add("opacity-0");
            websites[website_shown + 1].classList.remove("opacity-0");
            if (website_shown === websites.length - 2) {
              website_buttons[1].hidden = true;
              document
                .getElementById(website_buttons[1].sibbling)
                .classList.remove("opacity-0");
              website_buttons[1].name.classList.add("opacity-0");
            }
            if (website_shown === 0) {
              website_buttons[0].hidden = false;
              document
                .getElementById(website_buttons[0].sibbling)
                .classList.add("opacity-0");
              website_buttons[0].name.classList.remove("opacity-0");
            }
          }
        }
      }
    });
  }
}

const find_website_shown = function () {
  for (let x = 0; x < websites.length; x++) {
    if (websites[x].classList.contains("opacity-0")) {
      // pass
    } else {
      website_shown = x;
    }
  }
};

if (down_arrow !== null) {
  down_arrow.addEventListener("click", (e) => {
    document.querySelector("#about-us").scrollIntoView({ behavior: "smooth" });
  });
}

function onReady(callback) {
  var intervalId = window.setInterval(function () {
    if (document.getElementsByTagName("body")[0] !== undefined) {
      window.clearInterval(intervalId);
      callback.call(this);
    }
  }, 1000);
}

function setVisible(selector, visible) {
  document.querySelector(selector).style.display = visible ? "block" : "none";
}

onReady(function () {
  setVisible(".page", true);
  setVisible("#loading", false);
});
